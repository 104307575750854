<template>
  <div>
    <BuyerConfigurations v-if="company.buyer" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BuyerConfigurations from './Buyer/BuyerConfigurations';

export default {
  name: 'CompanyConfigurations',
  components: {
    BuyerConfigurations,
  },
  computed: {
    ...mapState('companies', ['company']),
  },
};
</script>
